<template>
  <div class="player">
    <div class="prism-player" id="player-con"></div>
    <div class="prism-player" id="player-con2" v-if="length == 2"></div>
  </div>
</template>

<script>
import { getPlayAuth } from "@/api/index";
export default {
  props: {
    vId: {
      type: Array,
    //   required: true
    },
    length: {
      type: Number,
    }
  },
  data () {
    return {
      videoId1: '',
      videoId2: '',
      playAuth1: '',
      playAuth2: '',
    //   coverURL: ''
    }
  },
  mounted () {
  	// 根据父组件传入的videoId调用接口获取播放凭证和视频封面
    if (this.vId) {
        let data1, data2;
        this.videoId1 = this.vId[0];
        data1 = {
            video_id: this.videoId1
        }
        if (this.vId.length > 1) {
            this.videoId2 = this.vId[1];
            data2 = {
                video_id: this.videoId2
            }
        }
        getPlayAuth(data1).then(res => {
            this.playAuth1 = res.data.voucher
        }).then(() => {
            this.getVideo()
        }).catch(err => {
            // this.$opener.close()
        });
        getPlayAuth(data2).then(res => {
            this.playAuth2 = res.data.voucher
        }).then(() => {
            this.getVideo()
        }).catch(err => {
            // this.$opener.close()
        })
    }
    // this.getVideo()
  },
  methods: {
    getVideo() {
      var player = new Aliplayer({
        "id": "player-con",
        "vid": this.videoId1,
        "playauth": this.playAuth1,
        "width": "100%",
        "height": "500px",
        "autoplay": false,
      }, function (player) {
          console.log("The player is created");
        }
      );
      var player2 = new Aliplayer({
        "id": "player-con2",
        "vid": this.videoId2,
        "playauth": this.playAuth2,
        "width": "100%",
        "height": "500px",
        "autoplay": false,
      }, function (player2) {
          console.log("The player is created");
        }
      );
    }
  }
}
</script>
<style lang='less' scoped>
.player {
    width: 100%;
    aspect-ratio: 18/5;
    display: flex;
    justify-content: center;
    border-radius: .06rem;
    object-fit: fill;
    .prism-player{
        width: 48% !important;
        height: 100% !important;
        margin: 0 auto;
        border-radius: .06rem;
        overflow: hidden;
        /deep/ .prism-big-play-btn,/deep/ .prism-big-play-btn .outter{
        width: .48rem;
        height: .48rem;
        bottom: .4rem !important;
      }
    }
  p {
    color: #fff;
  }
}
</style>