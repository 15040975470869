<template>
  <div class="body">
    <van-nav-bar left-text="返回" left-arrow @click-left="onpage">
      <template #right>
        <van-button
          v-if="number == 1"
          class="btn"
          type="warning"
          @click="complete('finish')"
          >完成评估</van-button
        >
        <div class="shownumber" v-if="number > 1">
          <div class="div1">正确{{ numtrues }}个</div>
          <div class="div2">错误{{ numfalses }}个</div>
        </div>
      </template>
    </van-nav-bar>
    <div class="from">
      <div class="mycard">
          <van-button class="pingubtn" type="danger">{{
            tagname + " - " + mileage_name
          }}</van-button>
          <div class="text">
            {{ "(" + coppydata.type + ")" + coppydata.title }}
          </div>
        </div>
        <div class="starting" v-if="btnshow" @click="addBtnFlag && twodata(i)">
          开始答题
        </div>
        <div class="starting" v-if="thisshow">
          本练习题结束
        </div>
      <div class="imageproblem" v-if="type === 'picture'">
        <div class="picturelist" v-if="imglist">
          <ul>
            <li v-for="item in imgsarr" :key="item.id">
              <img :src="item.url" alt="" />
            </li>

            <!-- <li><img src="../src/img/img2.png" alt="" /></li>
          <li><img src="../src/img/img3.png" alt="" /></li>
          <li><img src="../src/img/img4.png" alt="" /></li>
          <li><img src="../src/img/img8.png" alt="" /></li>
          <li><img src="../src/img/img9.png" alt="" /></li>
          <li><img src="../src/img/img10.png" alt="" /></li>
          <li><img src="../src/img/img11.png" alt="" /></li>
          <li><img src="../src/img/img3.png" alt="" /></li>
          <li><img src="../src/img/img4.png" alt="" /></li>
          <li><img src="../src/img/img8.png" alt="" /></li> -->
          </ul>
        </div>
        <div class="sample" v-if="src && !btnshow">
          <img :src="src" alt="" />
          <p>样本图片</p>
        </div>
      </div>
      <div class="videoproblem" v-if="type === 'video'">
        <div class="toovideo" v-if="!btnshow">
          <!-- <video controls v-for="item in videosarr" :key="item.id">
            <source :src="item.name" type="video/mp4" />
            您的浏览器不支持 HTML5 video 标签。
          </video> -->
          <aliplayer :vId="this.vId" :length="this.videosarr.length"></aliplayer>
        </div>
        <!-- <div class="btngroup" v-if="btngroup">
          <div class="xxBox" style="margin: 0 auto">
            <button v-for="(optionItem,i) in optionArr" :key="i" @click="choose(optionItem, $event)">{{optionItem.content}}</button>
          </div> -->
<!-- 
          <button :class="{ trues: istrue }" @click="choose('true')">
            正确
          </button>
          <button :class="{ falses: isfalse }" @click="choose('false')">
            错误
          </button> -->
        <!-- </div> -->
      </div>
      <div class="btngroup" v-if="btngroup">
          <div class="xxBox" style="margin: 0 auto">
            <button v-for="(optionItem,i) in optionArr" :key="i" @click="choose(optionItem, $event)">{{optionItem.content}}</button>
          </div>
          <!-- <button :class="{ trues: istrue }" @click="choose('true')">
            正确
          </button>
          <button :class="{ falses: isfalse }" @click="choose('false')">
            错误
          </button> -->
        </div>
    </div>
    <div class="scorebtn" @click="scoreshow">
      <div class="box"></div>
    </div>
    <div class="picbtn" v-if="picbtn">
      <div class="buttongroup">
        <div
          class="picpre pb"
          style="border: 1px solid #ff8a88"
          @click="picpre"
          v-show="picpreshow"
        >
          上一题
        </div>
        <div
          class="picnext pb"
          style="border: 1px solid #ff8a88"
          @click="picnext"
          v-show="picnextshow"
        >
          下一题
        </div>
      </div>
    </div>
    <div class="fixedcard">
      <div
        class="buttongroup preTopic"
        v-if="this.coppydata.topic_sort > 1"
        @click="ona"
      >
        <div style="border: 1px solid #ff8a88">上一题</div>
      </div>
      <!-- <div class="item">
        <span class="iconfont icon-ICON myicon" @click="opentable"></span
        ><span>项目记录(总数4个)</span>
      </div> -->
      <div class="score">
        <span
          :class="{ active: scorerecord.fullscore === 0, active1: zero }"
          @click="scoreclick(0)"
          >0分</span
        >
        <span
          :class="{ active: scorerecord.fullscore == 0.5, active1: zerofive }"
          @click="scoreclick(0.5)"
          >0.5分</span
        >
        <span
          :class="{ active: scorerecord.fullscore == 1, active1: one }"
          @click="scoreclick(1)"
          >1分</span
        >
      </div>
      <div class="buttongroup">
        <div
          v-if="text === '手动'"
          style="background: #eea837; color: #fff"
          @click="manual('手动')"
        >
          手动打分
        </div>
        <div
          v-if="text === '自动'"
          style="background: #eea837; color: #fff"
          @click="manual('自动')"
        >
          恢复自动
        </div>
        <div style="border: 1px solid #ff8a88" @click="next">下一题</div>
      </div>
    </div>
    <!-- 分数弹框 -->
    <van-popup class="scorebox" v-model="show">
      <div
        class="calculatescore"
        v-for="(item, index) in scorearr"
        :key="index"
      >
        <p @click="backSee(item.topic_sort, item.toprecord_id)">
          {{ item.name }}
        </p>
        <ul>
          <li :class="{ active: item.score === 0 || item.score === '0.0' }">0分</li>
          <li :class="{ active: item.score == 0.5 }">0.5分</li>
          <li :class="{ active: item.score == 1 }">1分</li>
        </ul>
      </div>
    </van-popup>
    <van-overlay
      :show="overlayShow"
      :custom-style="{ background: 'rgba(0,0,0,0)', zIndex: '99' }"
      @click="overlayTip"
      :lock-scroll="false"
    />
  </div>
</template>

<script>
import { Notify, Dialog, Toast, Overlay } from "vant";
import "../../../plugin/element";
import {
  getuid,
  getreviewid,
  gettopicid,
  getltemname,
  getnext,
  settopicid,
  setltemname,
} from "/src/api/userdata.js";
import {
  getweidu,
  getexercises,
  gettablelist,
  rightscore,
  recordadd,
  topicRecord,
  finishAsses,
} from "../../../api/index";
import aliplayer from '@/components/aliplay';

export default {
  data() {
    return {
      num: 1,
      num1: 0,
      show: false,
      nextarr: [],
      scorearr: [],
      numindex: "",
      text: "手动",
      tableshow: false,
      currentIndex: "",
      istrue: false,
      isfalse: false,
      src: "",
      type: "picture",
      videosrc: "https://www.runoob.com/try/demo_source/movie.mp4",
      // 练习题的数据
      btnshow: false,
      coppydata: [],
      tagname: "",
      mileage_name: "",
      idarr: {
        uid: "",
        reviewid: "",
        mainid: "",
      },
      number: "",
      i: 0,
      mycard: false,
      completeshow: true,
      imgsarr: [],
      videosarr: [],
      numtrue: 0,
      numfalse: 0,
      num: 0,
      // 分数按钮
      zero: false,
      zerofive: false,
      one: false,
      auto: "",
      scorerecord: {
        fullscore: "",
        fullcoppy: "",
        halfscore: "",
        halfcoppy: "",
      },
      btngroup: false,
      isok: false,
      numtrues: 0,
      numfalses: 0,
      numberof: 0,
      imglist: false,
      picbtn: false,
      picpreshow: true,
      picnextshow: true,
      picindex: 0,
      recordArr: [],
      addBtnFlag: true,
      picid: 0,
      nextname: "",
      topic_sort: 0,
      questionid: "",
      trecord_id: "", //编辑必传—评估记录主表id
      toprecord_id: "", //编辑必传—答题记录主表id
      //答题记录，练习题和判断题必传，
      //id为空添加；id和topic_id同时存在值编辑；id存在值，topic_id为空删除，
      //json格式：[{“id”:3,”topic_id”:1,”answer”:1},{“id”:0,”topic_id”:1,”answer”:0}]
      setarr: [],
      overlayShow: false,
      isLive: false,
      visitable: false,
      videoUrl: "",
      vId: [],
      thisshow: false,
      optionArr: [],
      answers: [],
      userAnswer: [],
      yesNum: 0,
      isend: true
    };
  },
  // props: ["exercisesdata","getlist"],
  created() {
    this.getlist();
  },
  mounted() {
    this.getNextMain();
    // this.opena();
    // getVideo()
  },
  components: { "van-overlay": Overlay, aliplayer },
  methods: {
    getlist() {
      const uid = getuid();
      // console.log(uid + '测试uid');
      const reviewid = getreviewid();
      const mainid = gettopicid();
      this.tagname = getltemname();
      // console.log("获取练习题传递的参数：" + uid, reviewid, mainid);
      if (this.$route.query.topic_sort) {
        let preTopicSort = this.$route.query.topic_sort;
        let not = this.$route.query.not;
        console.log(uid, preTopicSort, reviewid, mainid); //1636 19 32 985
        topicRecord(uid, preTopicSort, reviewid, mainid).then((res) => {
          console.log(res);
          if(res.code === 9990){
            Notify({ type: 'danger', message: res.msg});
            this.$router.push({
              name: 'login'
            })
          }
          this.coppydata = res.data.topic_info;
          this.mileage_name = this.coppydata.mileage_name;
          this.num = this.coppydata.main_id;
          this.topic_sort = this.coppydata.topic_sort;
          this.questionid = this.coppydata.id;
          this.trecord_id = res.data.trecord_id;
          this.toprecord_id = res.data.id;
          this.scorerecord.fullscore = res.data.score;
          let answerArr = res.data.son_toprecrd;
          console.log(this.scorerecord.fullscore);
          if (not == 1) {
            this.overlayShow = true;
          }
          console.log(this.coppydata);
          for (let i = 0; i < this.coppydata.pract_ids.length; i++) {
            const element = this.coppydata.pract_ids[i].id;
            const answerId = answerArr[i].id;
            const answer = answerArr[i].answer;
            this.setarr[i] = {
              id: answerId,
              topic_id: element,
              answer: answer,
            };
            console.log(answerId);
          }
          console.log(this.setarr);
          this.i = this.coppydata.pract_ids[0].id;
          this.twodata(this.i);
          this.getdata();
        });
      } else {
        gettablelist(uid, reviewid, mainid).then((res) => {
          if(res.code === 9990){
            Notify({ type: 'danger', message: res.msg});
            this.$router.push({
              name: 'login'
            })
          }
          console.log(res);
          this.coppydata = res.data;
          console.log(this.coppydata);
          this.mileage_name = this.coppydata.mileage_name;
          this.num = this.coppydata.main_id;
          this.topic_sort = this.coppydata.topic_sort;
          this.questionid = this.coppydata.id;
          this.trecord_id = this.coppydata.trecord_id;
          for (let i = 0; i < this.coppydata.pract_ids.length; i++) {
            const element = this.coppydata.pract_ids[i].id;
            this.setarr[i] = { id: "", topic_id: element, answer: 0 };
          }
          console.log(this.setarr);
          this.i = this.coppydata.pract_ids[0].id;
          // let id = res.data.id
          // console.log(id)
          this.twodata(this.i);
          this.getdata();
        });
      }
    },
    // getlist() {
    //   const uid = getuid();
    //   const reviewid = getreviewid();
    //   const mainid = gettopicid();
    //   this.name = getltemname();
    //   gettablelist(uid, reviewid, mainid).then((res) => {
    //     if (res.data.topic_type === "1") {

    //     } else if (res.data.topic_type === "2") {

    //       this.exercisesdata = res.data;
    //     }
    //   });
    // },
    getNextMain() {
      let thisMainIndex = '';
      const uid = getuid();
      const id = getreviewid();
      getweidu(uid, id).then((res) => {
        this.nextarr = res.data.list;
        // console.log(this.nextarr);
        // console.log(this.num);
        for (let i = 0; i < this.nextarr.length; i++) {
          const element = this.nextarr[i].main_id;
          if (this.num == this.nextarr[this.nextarr.length - 1].main_id) {
            this.nextname = "最后一个维度了";
          }
          if (this.num == element) {
            // console.log(this.nextarr);
            thisMainIndex = i;
          }else if (this.num != element && this.nextarr[i].status  != 1) {
            this.isend = false
          }
          if(thisMainIndex == 0 && i > thisMainIndex && this.nextarr[i].status != 1){
            this.nextmainid = this.nextarr[i].main_id;
            this.nextname = this.nextarr[i].main_name;
            return false
          }
          // console.log(thisMainIndex);
          if (thisMainIndex && i > thisMainIndex && this.nextarr[i].status != 1) {
            console.log(this.nextarr[i]);
            this.nextmainid = this.nextarr[i].main_id;
            this.nextname = this.nextarr[i].main_name;
            return false;
          }else if(thisMainIndex && i > thisMainIndex && this.nextarr[i].status == 1){
            this.nextname = "最后一个维度了";
          }
        }
        // this.num = indexid
        // console.log(this.nextname, this.nextmainid);
      });
    },
    getdata() {
      this.idarr.uid = getuid();
      this.idarr.reviewid = getreviewid();
      this.idarr.mainid = gettopicid();
      console.log(this.coppydata);
      if (this.coppydata.pract_ids.length > 1) {
        this.btnshow = true;
        this.btngroup = false;
        // this.number = this.coppydata.pract_ids.length;
        this.imglist = false;
      } else {
        this.mycard = true;
        // this.twodata(this.i)
        this.btnshow = false;
        this.btngroup = true;
      }
      this.number = 1;
      this.tagname = getltemname();
    },
    twodata(index) {
      if (this.btnshow) {
        this.number = this.coppydata.pract_ids.length;
        this.picbtn = true;
        this.picpreshow = false;
      }
      this.btnshow = false;
      this.btngroup = true;
      this.imglist = true;
      getexercises(index).then((res) => {
        console.log(res, "练习题返回的数据");
        if (res.code === 1) {
          this.coppydata.title = res.data.title;
          this.picid = res.data.id;
          this.src = res.data.sample;
          let content = res.data.top_content;
          let option = res.data.option;
          let answer = res.data.answer;
          this.answers = JSON.parse(answer);
          this.optionArr = JSON.parse(option);
          if (res.data.type == "1") {
            this.coppydata.type = "单选";
          }
          if (res.data.type == "2") {
            this.coppydata.type = "多选";
          }
          // this.imgsarr = [];
          // this.videosarr = [];
          // this.opena();
          // console.log(this.answers);
          if (content.img.length > 0) {
            this.type = "picture";
            const imgbox = content.img;
            this.imgsarr = imgbox;
          } else if (content.video.length > 0) {
            this.type = "video";
            const videobox = content.video;
            this.videosarr = videobox;
            for(let i in this.videosarr){
              this.vId.push(this.videosarr[i].video_id)
            }
          }
          // console.log(imgbox)
        } else {
          Notify({ type: 'danger', message: res.msg});
        }
      });
    },
    scoreclick(val) {
      if (this.auto == "手动") {
        this.zero = false;
        this.zerofive = false;
        this.one = false;
        if (val === 0) {
          this.zero = true;
          this.zerofive = false;
          this.one = false;
          this.scorerecord.fullscore = 0;
        } else if (val === 0.5) {
          this.zerofive = true;
          this.zero = false;
          this.one = false;
          this.scorerecord.fullscore = 0.5;
        } else if (val === 1) {
          this.one = true;
          this.zero = false;
          this.one = false;
          this.scorerecord.fullscore = 1;
        }
      }
    },
    findTrueFalse() {
      for (let index = 0; index < this.recordArr.length; index++) {
        const value = this.recordArr[index];
        if (value == "正确") {
          this.numtrues++;
        }
        if (value == "错误") {
          this.numfalses++;
        }
      }
    },
    picpre() {
      // 点击练习题中的上一题
      // this.numberof -= 1;
      this.numtrues = 0;
      this.numfalses = 0;
      this.istrue = false;
      this.isfalse = false;
      this.userAnswer = [];
      let romoveList = document.getElementsByClassName("xxBox")[0].children;
      for (let i = 0; i < romoveList.length; i++) {
        const element = romoveList[i];
        let romoveClass = element.getAttribute("class");
        if (romoveClass) {
          element.classList.remove("trues")
        }
      }
      this.yesNum = 0;
      this.picindex -= 1;
      let nextid = this.coppydata.pract_ids[this.picindex].id;
      this.recordArr.pop();
      this.findTrueFalse();
      this.twodata(nextid);
      console.log(this.recordArr);
      this.picnextshow = true;
      if (this.picindex <= 0) {
        this.picpreshow = false;
      }
    },
    picnext() {
      // 点击练习题中的下一题
      console.log(this.istrue,this.isfalse);
      const fullmark = Number(this.coppydata.full_mark);
      const halfmark = Number(this.coppydata.half_mark);
      let piclength = this.coppydata.pract_ids.length;
      this.numtrues = 0;
      this.numfalses = 0;
      if (this.istrue) {
        this.recordArr.push("正确");
      } else if (this.isfalse) {
        this.recordArr.push("错误");
      }
      this.findTrueFalse();
      this.istrue = false;
      this.isfalse = false;
      this.userAnswer = [];
      let romoveList = document.getElementsByClassName("xxBox")[0].children;
      this.yesNum = 0;
      this.picindex += 1;
      console.log(this.numtrues);
      this.picpreshow = true;
      // this.type = ''
      // console.log(this.picindex);
      if (this.picindex == piclength - 1) {
        Dialog.alert({
          title: "提示",
          message: "即将开始本练习题的最后一题",
          confirmButtonColor: "#fff",
          messageAlign: "left",
          getContainer: ".body",
        }).then(() => {
          let nextid = this.coppydata.pract_ids[this.picindex].id;
          this.twodata(nextid);
        });
      }
      if (this.numtrues >= fullmark) {
        this.scorerecord.fullscore = 1;
        this.scorerecord.fullcoppy = 1;
        this.isok = true;
        console.log("1分");
      } else if (this.numtrues < fullmark && this.numtrues >= halfmark) {
        this.scorerecord.fullscore = 0.5;
        this.scorerecord.fullcoppy = 0.5;
        this.isok = true;
        console.log("0.5分");
      } else if (this.numtrues < halfmark) {
        this.scorerecord.fullscore = 0;
        this.scorerecord.fullcoppy = 0;
        this.isok = true;
        console.log("0分");
      }
      if (this.picindex >= piclength) {
        this.picbtn = false;
        // this.imglist = false;
        // this.btngroup = false;
        // this.thisshow = true;
        this.addBtnFlag = false;
        this.number = 1;
        console.log("结束");
      } else {
        for (let i = 0; i < romoveList.length; i++) {
          const element = romoveList[i];
          let romoveClass = element.getAttribute("class");
          if (romoveClass) {
            element.classList.remove("trues")
          }
        }
        let nextid = this.coppydata.pract_ids[this.picindex].id;
        this.twodata(nextid);
      }
      // console.log(this.coppydata);
      // console.log(this.numtrues);
    },

    choose(val, e) {
      // console.log(this.picid);
      if (this.coppydata.pract_ids.length > 1 && this.number == 1) {
        return false
      }
      console.log(val);
      const fullmark = Number(this.coppydata.full_mark);
      const halfmark = Number(this.coppydata.half_mark);
      let classList = e.currentTarget.getAttribute("class");
      // let userAnswer = [];
      let n = 0;
      if (classList) {
        e.currentTarget.classList.remove("trues");
        for (let x = 0; x < this.userAnswer.length; x++) {
          const element = this.userAnswer[x];
          if (element.key == val.key) {
            this.userAnswer.splice(x, 1)
          }
        }
        for (let z = 0; z < this.answers.length; z++) {
          const an = this.answers[z];
          if (val.key === an.key) {
            this.yesNum--
          }
        }
        if (this.coppydata.pract_ids.length == 1) {
          this.numtrues = this.yesNum
        }
        console.log(this.yesNum);
      }else{
        e.currentTarget.classList.add("trues");
        this.userAnswer.push({
          key: val.key,
          option: val.option
        })
        for (let z = 0; z < this.answers.length; z++) {
          const an = this.answers[z];
          if (val.key === an.key) {
            this.yesNum++
          }
        }
        if (this.coppydata.pract_ids.length == 1) {
          if (this.yesNum === this.userAnswer.length) {
            this.istrue = true;
            this.isfalse = false;
            n = 1;
            this.numtrues += 1;
            if (this.numfalses > 0) {
              this.numfalses -= 1;
            }
          }else{
            this.isfalse = true;
            this.istrue = false;
            n = 0;
            this.numfalses += 1;
            if (this.numtrues > 0) {
              this.numtrues -= 1;
            }
          }
        }
        console.log(this.yesNum);
      }
      // console.log(this.yesNum,this.answers.length);
      if (this.yesNum === this.answers.length && this.userAnswer.length == this.answers.length) {
        this.istrue = true
      }else{
        this.isfalse = true
      }
      // console.log(this.yesNum);
      // this.btnClass = !this.btnClass;
      // this.getdata()
      // console.log("满分的正确数量：", fullmark, "||半分的正确数量：", halfmark);
      // if (this.coppydata.pract_ids.length == 1) {
      //   // console.log(this.yesNum,this.userAnswer.length);
      //   if (this.yesNum === this.userAnswer.length) {
      //     this.istrue = true;
      //     this.isfalse = false
      //   }else{
      //     this.isfalse = true;
      //     this.istrue = false
      //   }
      //   if (this.istrue) {
      //     this.isfalse = false;
      //     n = 1;
      //     this.numtrues += 1;
      //     if (this.numfalses > 0) {
      //       this.numfalses -= 1;
      //     }
      //   }
      //   if (this.isfalse) {
      //     this.istrue = false;
      //     n = 0;
      //     this.numfalses += 1;
      //     if (this.numtrues > 0) {
      //       this.numtrues -= 1;
      //     }
      //   }
      // }
      for (let i = 0; i < this.setarr.length; i++) {
        const topic_id = this.setarr[i].topic_id;
        if (this.picid == topic_id) {
          this.setarr[i].answer = n;
        }
      }
      // console.log(this.setarr);
      // console.log(this.numtrues);
      // console.log(
      //   "正确数量：",
      //   this.numtrues,
      //   "满分的正确数量：",
      //   fullmark,
      //   "||半分的正确数量：",
      //   halfmark
      // );
      console.log(this.numtrues);
      if (this.numtrues >= fullmark) {
        this.scorerecord.fullscore = 1;
        this.scorerecord.fullcoppy = 1;
        this.isok = true;
        console.log("1分");
      } else if (this.numtrues < fullmark && this.numtrues >= halfmark) {
        this.scorerecord.fullscore = 0.5;
        this.scorerecord.fullcoppy = 0.5;
        this.isok = true;
        console.log("0.5分");
      } else if (this.numtrues < halfmark) {
        this.scorerecord.fullscore = 0;
        this.scorerecord.fullcoppy = 0;
        this.isok = true;
        console.log("0分");
      }
    },
    next() {
      this.istrue = false;
      this.isfalse = false;
      //       const uid = getuid();
      // const reviewid = getreviewid();
      // // console.log(picid);
      //       let data = {
      //         uid: uid,
      //         review_id: reviewid,
      //         topic_id: this.coppydata.id,
      //         score: this.scorerecord.fullscore,
      //         ans_record: JSON.stringify(this.setarr)
      //       }
      //       recordadd(data).then((res) => {
      //         console.log(res);
      //       })
      this.complete("add");
    },
    ona() {
      this.istrue = false;
      this.isfalse = false;
      this.complete("jian");
    },
    // getnexts() {
    //   const uid = getuid();
    //   const id = getreviewid();
    //   // console.log(uid, id);
    //   getweidu(uid, id).then((res) => {
    //     this.nextarr = res.data;
    //     // console.log(this.nextarr, "123131313");
    //     const indexid = Number(getnext());
    //     this.num1 = indexid;
    //   });
    // },
    complete(val) {
      let uid = getuid();
      let reviewid = getreviewid();
      let mainid = gettopicid();
      if (val == "add") {
        rightscore(uid, reviewid, mainid).then((res) => {
          // console.log(res)
          this.allTopic = res.data;
          let lastId = this.allTopic[this.allTopic.length - 1].id;
          if (this.scorerecord.fullscore === "") {
            Dialog.alert({
              title: "提示",
              message: "请作答当前题目",
              confirmButtonColor: "#fff",
              messageAlign: "left",
              getContainer: ".body",
            }).then(() => {
              // on close
            });
          }
          if (
            this.scorerecord.fullscore === 0 ||
            this.questionid == lastId
          ) {
            console.log(uid, reviewid, this.scorerecord.fullscore);
            let data = {
              trecord_id: this.trecord_id,
              toprecord_id: this.toprecord_id,
              uid: uid,
              review_id: reviewid,
              main_id: mainid,
              topic_id: this.questionid,
              score: this.scorerecord.fullscore,
              ans_record: JSON.stringify(this.setarr),
            };
            console.log(data);
            recordadd(data).then((res) => {
              console.log(res, "添加答题记录");
              if (res.code == "1") {
                this.nextMainConfirm(uid, reviewid, mainid);
                Notify({ type: 'success', message: res.msg});
              }
            });
          } else {
            let data = {
              trecord_id: this.trecord_id,
              toprecord_id: this.toprecord_id,
              uid: uid,
              review_id: reviewid,
              main_id: mainid,
              topic_id: this.questionid,
              score: this.scorerecord.fullscore,
              ans_record: JSON.stringify(this.setarr),
            };
            console.log(data);
            recordadd(data).then((res) => {
              console.log(res, "添加答题记录");
              if (res.code == "1") {
                gettablelist(uid, reviewid, mainid).then((res) => {
                  console.log(res, "加表格的数据");
                  // return
                  if (res.data.topic_type === "1") {
                    this.$router.push({
                      name: "addtable",
                    });
                  } else if (res.data.topic_type === "2") {
                    console.log(res.data.topic_type);
                    this.$router.push({
                      name: "choosepicture",
                    });
                    window.location.reload();
                  } else if (res.data.topic_type === "3") {
                    this.$router.push({
                      name: "addTruefalse",
                    });
                  }
                });
                Notify({ type: 'success', message: res.msg});
              }
            });
          }
        });
      } else if (val == "jian") {
        let preTopicSort = this.topic_sort - 1;
        console.log(uid, preTopicSort, reviewid, mainid); //1636 1 32 985
        topicRecord(uid, preTopicSort, reviewid, mainid).then((res) => {
          console.log(res);
          if (res.data.topic_type === "1") {
            this.$router.push({
              name: "addtable",
              query: {
                topic_sort: preTopicSort,
              },
            });
          } else if (res.data.topic_type === "2") {
            console.log(res.data.topic_type);
            this.$router.push({
              name: "choosepicture",
              query: {
                topic_sort: preTopicSort,
              },
            });
            window.location.reload();
          } else if (res.data.topic_type === "3") {
            this.$router.push({
              name: "addTruefalse",
              query: {
                topic_sort: preTopicSort,
              },
            });
          }
        });
      } else if (val == "finish") {
        if (this.scorerecord.fullscore == "") {
          this.scorerecord.fullscore = 0;
        }
        console.log(uid, reviewid, this.scorerecord.fullscore);
        let data = {
          trecord_id: this.trecord_id,
          toprecord_id: this.toprecord_id,
          uid: uid,
          review_id: reviewid,
          main_id: mainid,
          topic_id: this.questionid,
          score: this.scorerecord.fullscore,
          ans_record: JSON.stringify(this.setarr),
        };
        console.log(data);
        recordadd(data).then((res) => {
          console.log(res, "添加答题记录");
          if (res.code == "1") {
            this.nextMainConfirm(uid, reviewid, mainid);
            Notify({ type: 'success', message: res.msg});
          }
        });
      }
    },
    // 添加答题记录
    // nextTopic(uid, reviewid, mainid) {
    //   if (this.scorerecord.fullscore == "") {
    //     this.scorerecord.fullscore = 0;
    //   }
    //   console.log(uid, reviewid, this.scorerecord.fullscore);
    //   let data = {
    //     trecord_id: this.trecord_id,
    //     toprecord_id: this.toprecord_id,
    //     uid: uid,
    //     review_id: reviewid,
    //     main_id: mainid,
    //     topic_id: this.questionid,
    //     score: this.scorerecord.fullscore,
    //     ans_record: JSON.stringify(this.setarr),
    //   };
    //   console.log(data);
    //   recordadd(data).then((res) => {
    //     console.log(res, "添加答题记录");
    //     Notify(res.msg);
    //   });
    // },
    // 下一维度
    nextMainConfirm(uid, reviewid, mainid) {
      console.log(this.nextname,this.nextmainid);
      let data = {
        uid: uid,
        review_id: reviewid,
        main_id: mainid,
      };
      finishAsses(data).then((res) => {
        Notify({ type: 'success', message: res.msg});
        if (this.nextname == "最后一个维度了") {
          if (this.isend) {
            Toast.success({
              duration: 3000,
              forbidClick: true,
              message: "恭喜你，完成评估",
              onClose: () => {
                this.$router.push({
                  name: "startpingu",
                });
              },
            });
          }else{
            Dialog.alert({
              title: "提示",
              message: "本维度已完成评估，请选择未完成的维度继续作答",
              confirmButtonColor: "#fff",
              messageAlign: "left",
              getContainer: ".body",
            }).then(() => {
              this.$router.push({
                name: "startpingu",
              });
            });
          }
        } else {
          this.$dialog
            .confirm({
              title: "提示",
              message:
                "本维度的测评已结束，下一个测评维度是" +
                '<span style="color:red">' +
                this.nextname +
                "</span>",
              confirmButtonColor: "#fff",
              messageAlign: "left",
              confirmButtonText: "继续评估",
              cancelButtonText: "手动调整",
              getContainer: ".body",
            })
            .then(() => {
              settopicid(this.nextmainid);
              setltemname(this.nextname);
              let nextuid = getuid();
              let nextreviewid = getreviewid();
              console.log(nextuid, nextreviewid, this.nextmainid);
              gettablelist(nextuid, nextreviewid, this.nextmainid).then(
                (res) => {
                  console.log(res);
                  if (res.data.topic_type === "1") {
                    this.$router.push({
                      name: "addtable",
                    });
                  } else if (res.data.topic_type === "2") {
                    this.$router.push({
                      name: "choosepicture",
                    });
                    window.location.reload();
                  } else if (res.data.topic_type === "3") {
                    this.$router.push({
                      name: "addTruefalse",
                    });
                  }
                }
              );
            })
            .catch(() => {
              this.$router.push({
                name: "startpingu",
              });
            });
        }
      });
    },
    getindex(val) {
      this.currentIndex = val;
    },
    onpage() {
      if (this.number > 1) {
        Dialog.confirm({
          title: "提示",
          message: "退出页面后练习题需重新作答，确定退出吗",
          confirmButtonColor: "#fff",
          messageAlign: "left",
          getContainer: ".body",
        })
          .then(() => {
            this.$router.push({
              name: "startpingu",
            });
          })
          .catch(() => {
            // on cancel
          });
      } else {
        this.$router.push({
          name: "startpingu",
        });
      }
    },
    manual(val) {
      this.auto = val;
      if (val === "手动") {
        const coppy = JSON.parse(JSON.stringify(this.scorerecord.fullscore));
        this.scorerecord.fullcoppy = coppy;
        this.scorerecord.fullscore = "";
        this.text = "自动";
        this.zero = false;
        this.zerofive = false;
        this.one = false;
        console.log(this.scorerecord.fullcoppy);
      } else {
        this.zero = false;
        this.zerofive = false;
        this.one = false;
        this.scorerecord.fullscore = this.scorerecord.fullcoppy;
        console.log(this.scorerecord.fullscore);
        this.text = "手动";
      }
    },
    deltablemodel() {
      this.tableshow = false;
    },
    opentable() {
      this.tableshow = !this.tableshow;
    },
    deleteitem() {
      this.$dialog
        .confirm({
          title: "提示",
          message: "删除项目记录后会影响本题得分，确定删除吗？",
          confirmButtonColor: "fff",
          messageAlign: "left",
          getContainer: ".body",
        })
        .then(() => {
          alert("确定");
          // on confirm
        })
        .catch(() => {
          alert("取消");
          // on cancel
        });
    },
    scoreshow() {
      const uid = getuid();
      const reviewid = getreviewid();
      const mainid = gettopicid();
      this.show = true;
      rightscore(uid, reviewid, mainid).then((res) => {
        console.log(res);
        this.scorearr = res.data;
      });
    },
    // 回看
    backSee(topic_sort, toprecord_id) {
      if (toprecord_id != 0) {
        let uid = getuid();
        let reviewid = getreviewid();
        let mainid = gettopicid();
        topicRecord(uid, topic_sort, reviewid, mainid).then((res) => {
          console.log(res);
          if (res.data.topic_type === "1") {
            this.$router.push({
              name: "addtable",
              query: {
                topic_sort: topic_sort,
              },
            });
          } else if (res.data.topic_type === "2") {
            console.log(res.data.topic_type);
            this.$router.push({
              name: "choosepicture",
              query: {
                topic_sort: topic_sort,
              },
            });
            window.location.reload();
          } else if (res.data.topic_type === "3") {
            this.$router.push({
              name: "addTruefalse",
              query: {
                topic_sort: topic_sort,
              },
            });
          }
        });
      }
    },
    overlayTip() {
      this.$dialog
        .confirm({
          title: "提示",
          message: "当前状态可查看，但无法编辑，点击确认即可返回维度列表",
          confirmButtonColor: "fff",
          messageAlign: "left",
          getContainer: ".body",
        })
        .then(() => {
          this.$router.push({
            name: "startpingu",
          });
        })
        .catch(() => {
          // console.log("取消");
        });
    },
    
    
  },
};
</script>

<style lang="less" scoped>
.body{
  min-height: 100vh;
  background: url(../src/img/bj.png) top left/100% auto no-repeat;
  padding-bottom: .7rem;
  button{
    border: none;
  }
  .van-nav-bar{
    background: none;
    &::after{
      border: none;
    }
    /deep/ .van-nav-bar__left{
      font-size: .14rem;
      .van-nav-bar__text{
        color: #666;
      }
      .van-icon{
        font-size: .14rem;
        color: #666;
        margin: 0;
      }
    }
    .btn {
      width: 1rem;
      height: .34rem;
      background: #f14947;
      border-radius: .06rem;
      font-size: .14rem;
      border: none;
    }
  }
  .from{
    padding: 0 16px;
  }
  .mycard {
    // width: 95%;
    padding: .13rem .16rem;
    margin: 0 auto;
    font-size: .16rem;
    background: #ffffff;
    border-radius: .06rem;
    margin-top: .1rem;
    box-shadow: 0 0 .1rem .01rem rgba(157,4,4,0.05);
    box-sizing: border-box;
    .pingubtn {
      height: .34rem;
      background: #EEA837;
      border-radius: .06rem;
      font-size: .16rem;
      overflow: hidden;
      float: left;
      margin-right: .16rem;
    }
    .text {
      color: #333333;
      line-height: .35rem;
      letter-spacing: .01rem;
    }
  }
  .scorebtn {
    height: .44rem;
    position: fixed;
    right: 0;
    top: 70%;
    display: flex;
    .box {
      width: .44rem;
      height: .44rem;
      background: #FFECEC;
      box-shadow: 0 0 .06rem .01rem rgba(110,8,8,0.23);
      &::before{
        position:absolute;
        top:.06rem;
        left:-.17rem;
        content:'';
        width:.32rem;
        height:.32rem;
        background:#FFECEC;
        transform: rotate(135deg);
        box-shadow: 0 0 .06rem .01rem rgba(110,8,8,0.23);
      }
      &::after{
        content:'分数';
        position:absolute;
        top:0;
        left: 0;
        width:.44rem;
        height:.44rem;
        background:#FFECEC;
        font-size: .15rem;
        color: #F14947;
        line-height: .44rem;
        text-align: center;
      }
    }
  }
  .scorebox {
    height: 100%;
    background: #ffffff;
    padding: .24rem .24rem .08rem;
    position: fixed;
    border-radius: .2rem .2rem 0 0;
    top: 0;
    right: 0;
    left: auto;
    transform: none;
  }
  .calculatescore {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: .16rem;
    p {
      color: #f14947;
      font-size: .16rem;
      margin: 0;
      width: .34rem;
    }
    ul {
      display: flex;
      li {
        width: .64rem;
        height: .34rem;
        background: #ffffff;
        border-radius: .17rem;
        border: 1px solid #cccccc;
        font-size: .15rem;
        color: #666666;
        text-align: center;
        line-height: .34rem;
        margin-left: .2rem;
      }
      li.active{
        background: #f1b207;
        color: #fff;
        border: 1px solid #f1b207;
      }
    }
  }
  .calculatescore:last-child{
    margin-bottom: 0.4rem;
  }
  .fixedcard {
    width: 100%;
    height: .7rem;
    background: #ffffff;
    box-shadow: 0 0 .06rem .01rem rgba(157, 4, 4, 0.16);
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 55;
    box-sizing: border-box;
    padding: 0 .2rem;
    .score {
      font-size: 0;
      span {
        display: inline-block;
        width: .56rem;
        height: .34rem;
        text-align: center;
        line-height: .34rem;
        background: #ffffff;
        border-radius: .17rem;
        border: 1px solid #cccccc;
        font-size: .15rem;
        color: #666666;
        margin-left: .1rem;
      }
      .active {
        background: #eea837;
        color: #fff;
        border-color: #eea837;
      }
      .active1 {
        background: #eea837;
        color: #fff;
        border-color: #eea837;
      }
    }
    .buttongroup {
      display: flex;
      div {
        width: .96rem;
        height: .34rem;
        border-radius: .17rem;
        opacity: 1;
        text-align: center;
        line-height: .34rem;
        font-size: .16rem;
        margin-left: .11rem;
      }
    }
    .item{
      flex-grow: 1;
      margin-left: .15rem;
      font-size: .16rem;
      span {
        margin-left: .13rem;
      }
      .myicon {
        color: #f14947;
        font-size: .16rem;
        margin-left: 0;
      }
    }
    .preTopic{
      height: fit-content;
      float: left;
      div{
        margin-left: 0;      
      }
    }
  }
  /deep/ .van-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
  /deep/ .van-dialog {
    width: 4.06rem;
    padding-bottom: .2rem;
    .van-dialog__header {
      text-align: left;
      padding: .2rem .24rem 0;
      color: #666;
    }
    .van-dialog__header::before {
      content: "!";
      display: inline-block;
      width: .18rem;
      height: .18rem;
      border-radius: 50%;
      text-align: center;
      font-size: .18rem;
      vertical-align: text-bottom;
      margin-right: .06rem;
      line-height: .2rem;
      padding: 0 0 .01rem .01rem;
      background: #ffaa20;
      color: #fff;
    }
    .van-dialog__message--has-title {
      padding-top: .1rem;
      padding-bottom: .27rem;
      font-size: .14rem;
      line-height: .3rem;
    }
    .van-dialog__footer {
      justify-content: center;
      overflow: initial;
      .van-button {
        width: .94rem;
        height: .34rem;
        flex: none;
        border-radius: .06rem;
        margin: 0 .13rem;
        font-size: .16rem;
      }
      .van-dialog__cancel {
        border: 1px solid #b2b2b2;
        color: #707070;
      }
      .van-dialog__confirm {
        background: #fa4b52;
        color: #fff;
      }
    }
    [class*="van-hairline"]::after {
      border: none;
    }
  }
  .starting {
    width: 1.2rem;
    height: .34rem;
    background: #f14947;
    border-radius: .06rem;
    text-align: center;
    font-size: .14rem;
    color: #fff;
    line-height: .34rem;
    margin: 1.7rem auto 0;
  }
  .shownumber {
    line-height: .32rem;
    border-radius: .06rem;
    border: 1px solid #ffadac;
    display: flex;
    justify-content: space-between;
    font-size: .14rem;
    .div1 {
      width: .9rem;
      height: .32rem;
      background: #ffffff;
      border-radius: .06rem 0 0 .06rem;
      color: #eea837;
    }
    .div2 {
      width: .9rem;
      height: .32rem;
      background: #ffeeee;
      border-radius: 0 .06rem .06rem 0;
      color: #f14947;
    }
  }
  .picturelist{
    padding: 0 .2rem;
    margin: .25rem auto 0;
    ul{
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      li{
        height: 1.5rem;
        margin: .1rem .05rem;
        border-radius: .1rem;
        overflow: hidden;
        img{
          height: 100%;
        }
        &:nth-child(even){
          margin-top: 0.3rem;
        }
      }
    }
  }
  .sample{
    width: 1.9rem;
    margin: 0 auto;
    background: #FFF8F8;
    border-radius: .1rem;
    border: 1px dashed #FFADAC;
    text-align: center;
    padding: .12rem;
    box-sizing: border-box;
    img{
      width: 100%;
      border-radius: .1rem;
    }
    p{
      margin: .05rem 0 0;
      font-size: .14rem;
      color: #666;
    }
  }
  .btngroup{
    width: 90%;
    margin: .5rem auto .2rem;
    display: flex;
    button {
      height: .34rem;
      border-radius: .06rem;
      border: 1px solid #cccccc;
      margin: .07rem;
      color: #999999;
      background: #fff;
      font-size: .14rem;
    }
    .trues, .falses {
      background: #fff3f3;
      border: 1px solid #ff8a88;
      color: #f14947;
    }
  }
  .picbtn .buttongroup {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: .3rem;
    .pb {
      width: .96rem;
      height: .34rem;
      background: #FE6765;
      border-radius: .17rem;
      color: #fff;
      text-align: center;
      line-height: .34rem;
      font-size: .16rem;
      margin: 0 .15rem;
      border: 0 !important;
    }
  }
  .toovideo {
    width: 95%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    margin-top: .3rem;
  }
}
</style>
