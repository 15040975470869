import vue from 'vue'
import vuex from 'vuex'
vue.use(vuex)
const actions = {}
const mutations = {
    getuid(state, val) {
        state.uid = val
    },
    getreviewid(state, val) {
        state.review_id = val
    },
    getmainid(state, val) {
        state.main_id = val
        console.log(state)
    },
}
const state = {
    uid: "",
    review_id: "",
    main_id: ""
}
const getters = {
    getdatas() {
        return state.uid
    }
}
export default new vuex.Store({
    actions,
    mutations,
    state,
    getters
})






















