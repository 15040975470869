<template>
  <div class="body">
    <van-nav-bar left-text="返回" left-arrow @click-left="onpage">
      <template #right>
        <van-button class="btn" type="warning" @click="complete('finish')"
          >完成评估</van-button
        >
      </template>
    </van-nav-bar>
    <div class="from">
      <div class="mycard">
        <van-button class="pingubtn" type="danger">
          {{ tagname + " - " + mileage_name }}
        </van-button>
        <div class="text">
          {{ addTruefalsedatas.title }}
        </div>
      </div>
    </div>
    <div class="choose">
      <div
        class="checkedbox"
        v-for="(item, index) in addTruefalsedatas.list"
        :key="item.id"
      >
        <p ref="box">{{ item.name }}</p>
        <div class="btngroup">
          <button
            :class="{ trues: active_tab1.indexOf(item.id) > -1 }"
            @click="choose('true', index, item.id)"
          >
            <van-icon name="success" />
          </button>
          <button
            :class="{ falses: active_tab2.indexOf(item.id) > -1 }"
            @click="choose('false', index, item.id)"
          >
            <van-icon name="cross" />
          </button>
        </div>
      </div>
    </div>
    <div class="scorebtn" @click="scoreshow">
      <div class="box"></div>
    </div>
    <div class="fixedcard">
      <div
        class="buttongroup preTopic"
        v-if="this.addTruefalsedatas.topic_sort > 1"
        @click="ona"
      >
        <div style="border: 1px solid #ff8a88">上一题</div>
      </div>

      <!-- <div class="score">
        <span
          v-for="(item, index) in score"
          :key="index"
          :class="{ active: currentIndex === index }"
          @click="getindex(index)"
          >{{ item }}</span
        >
      </div> -->
      <div class="score">
        <span
          :class="{ active: scorerecord.fullscore === 0, active1: zero }"
          @click="scoreclick(0)"
          >0分</span
        >
        <span
          :class="{ active: scorerecord.fullscore == 0.5, active1: zerofive }"
          @click="scoreclick(0.5)"
          >0.5分</span
        >
        <span
          :class="{ active: scorerecord.fullscore == 1, active1: one }"
          @click="scoreclick(1)"
          >1分</span
        >
      </div>
      <div class="buttongroup">
        <div
          v-if="text === '手动'"
          style="background: #eea837; color: #fff"
          @click="manual('手动')"
        >
          手动打分
        </div>
        <div
          v-if="text === '自动'"
          style="background: #eea837; color: #fff"
          @click="manual('自动')"
        >
          恢复自动
        </div>
        <div style="border: 1px solid #ff8a88" @click="next">下一题</div>
      </div>
    </div>
    <!-- 分数弹框 -->
    <van-popup class="scorebox" v-model="show">
      <div
        class="calculatescore"
        v-for="(item, index) in scorearr"
        :key="index"
      >
        <p @click="backSee(item.topic_sort, item.toprecord_id)">
          {{ item.name }}
        </p>
        <ul>
          <li :class="{ active: item.score === 0 || item.score === '0.0' }">0分</li>
          <li :class="{ active: item.score == 0.5 }">0.5分</li>
          <li :class="{ active: item.score == 1 }">1分</li>
        </ul>
      </div>
    </van-popup>
    <van-overlay
      :show="overlayShow"
      :custom-style="{ background: 'rgba(0,0,0,0)', zIndex: '99' }"
      @click="overlayTip"
      :lock-scroll="false"
    />
  </div>
</template>

<script>
import { Notify, Toast, Overlay, Dialog } from "vant";
import "../../../plugin/element";
import {
  getuid,
  getreviewid,
  gettopicid,
  getltemname,
  getnext,
  setltemname,
  settopicid,
} from "/src/api/userdata.js";
import {
  getweidu,
  getexercises,
  gettablelist,
  rightscore,
  recordadd,
  finishAsses,
  topicRecord,
} from "../../../api/index";

export default {
  data() {
    return {
      num: "",
      show: false,
      text: "手动",
      tableshow: false,
      istrue: false,
      isfalse: false,
      radio: "",
      score: ["0分", "0.5分", "1分"],
      currentIndex: "",
      // --------
      addTruefalsedatas: [],
      scorearr: [],
      tagname: "",
      mileage_name: "",
      idarr: {
        uid: "",
        reviewid: "",
        mainid: "",
      },
      activeindex: "",
      savenum: 0,
      scorerecord: {
        fullscore: "",
        fullcoppy: "",
        halfscore: "",
        halfcoppy: "",
      },
      zero: false,
      zerofive: false,
      one: false,
      auto: "",
      nextarr: [],
      num1: "",
      nextreviewid: "",
      nextmainid: "",
      nextuid: "",
      active_tab1: [],
      active_tab2: [],
      setarr: [],
      questionid: "",
      toprecord_id: "",
      trecord_id: "",
      topic_sort: 0,
      overlayShow: false,
      isend: true
    };
  },
  // props: ["addTruefalsedata", "getlist"],
  created() {
    this.getlist();
  },
  mounted() {
    // this.getdata();
    this.getNextMain();
  },
  components: { "van-overlay": Overlay },
  methods: {
    // getdata() {
    //   this.idarr.uid = getuid();
    //   this.idarr.reviewid = getreviewid();
    //   this.idarr.mainid = gettopicid();
    //   this.addTruefalsedatas = this.addTruefalsedata;
    //   this.tagname = getltemname();
    //   console.log(this.addTruefalsedatas, "2313");
    // },
    getlist() {
      this.idarr.uid = getuid();
      this.idarr.reviewid = getreviewid();
      this.idarr.mainid = gettopicid();
      this.tagname = getltemname();
      if (this.$route.query.topic_sort) {
        let preTopicSort = this.$route.query.topic_sort;
        let not = this.$route.query.not;
        console.log(
          this.idarr.uid,
          preTopicSort,
          this.idarr.reviewid,
          this.idarr.mainid
        ); //1636 19 32 985
        topicRecord(
          this.idarr.uid,
          preTopicSort,
          this.idarr.reviewid,
          this.idarr.mainid
        ).then((res) => {
          console.log(res);
          if(res.code === 9990){
            Notify({ type: 'danger', message: res.msg});
            this.$router.push({
              name: 'login'
            })
          }
          this.addTruefalsedatas = res.data.topic_info;
          this.mileage_name = this.addTruefalsedatas.mileage_name;
          this.questionid = this.addTruefalsedatas.id;
          this.num = this.addTruefalsedatas.main_id;
          this.trecord_id = res.data.trecord_id;
          this.toprecord_id = res.data.id;
          this.topic_sort = this.addTruefalsedatas.topic_sort;
          this.scorerecord.fullscore = res.data.score;
          let answerArr = res.data.son_toprecrd;
          if (not == 1) {
            this.overlayShow = true;
          }
          console.log(this.addTruefalsedatas, "this.addTruefalsedata");
          for (let i = 0; i < this.addTruefalsedatas.list.length; i++) {
            const element = this.addTruefalsedatas.list[i].id;
            const answerId = answerArr[i].id;
            const answer = answerArr[i].answer;
            this.setarr[i] = {
              id: answerId,
              topic_id: element,
              answer: answer,
            };
          }
        });
      } else {
        gettablelist(
          this.idarr.uid,
          this.idarr.reviewid,
          this.idarr.mainid
        ).then((res) => {
          if(res.code === 9990){
            Notify({ type: 'danger', message: res.msg});
            this.$router.push({
              name: 'login'
            })
          }
          this.addTruefalsedatas = res.data;
          this.mileage_name = this.addTruefalsedatas.mileage_name;
          this.questionid = this.addTruefalsedatas.id;
          this.num = this.addTruefalsedatas.main_id;
          this.trecord_id = this.addTruefalsedatas.trecord_id;
          this.topic_sort = this.addTruefalsedatas.topic_sort;
          console.log(this.addTruefalsedatas, "this.addTruefalsedata");
          for (let i = 0; i < this.addTruefalsedatas.list.length; i++) {
            const element = this.addTruefalsedatas.list[i].id;
            this.setarr[i] = { id: "", topic_id: element, answer: 0 };
          }
          // console.log(res, "res");
          // this.twodata(0);
        });
      }
    },
    getNextMain() {
      let thisMainIndex = '';
      const uid = getuid();
      const id = getreviewid();
      getweidu(uid, id).then((res) => {
        this.nextarr = res.data.list;
        console.log(this.nextarr);
        // console.log(this.num);
        for (let i = 0; i < this.nextarr.length; i++) {
          const element = this.nextarr[i].main_id;
          if (this.num == this.nextarr[this.nextarr.length - 1].main_id) {
            this.nextname = "最后一个维度了";
          }
          if (this.num == element) {
            // console.log(this.nextarr);
            thisMainIndex = i;
          }else if (this.num != element && this.nextarr[i].status  != 1) {
            this.isend = false
          }
          if(thisMainIndex == 0 && i > thisMainIndex && this.nextarr[i].status != 1){
            this.nextmainid = this.nextarr[i].main_id;
            this.nextname = this.nextarr[i].main_name;
            return false
          }
          // console.log(thisMainIndex);
          if (thisMainIndex && i > thisMainIndex && this.nextarr[i].status != 1) {
            // console.log(this.nextarr[i]);
            this.nextmainid = this.nextarr[i].main_id;
            this.nextname = this.nextarr[i].main_name;
            return false;
          }else if(thisMainIndex && i > thisMainIndex && this.nextarr[i].status == 1){
            this.nextname = "最后一个维度了";
          }
        }
        console.log(this.nextmainid);

        // this.num = indexid
        // console.log(this.nextname, this.nextmainid);
      });
    },

    scoreclick(val) {
      if (this.auto == "手动") {
        this.zero = false;
        this.zerofive = false;
        this.one = false;
        if (val === 0) {
          this.zero = true;
          this.zerofive = false;
          this.one = false;
          this.scorerecord.fullscore = 0;
        } else if (val === 0.5) {
          this.zerofive = true;
          this.zero = false;
          this.one = false;
          this.scorerecord.fullscore = 0.5;
        } else if (val === 1) {
          this.one = true;
          this.zero = false;
          this.one = false;
          this.scorerecord.fullscore = 1;
        }
      }
    },
    next() {
      this.complete("add");
    },
    ona() {
      this.complete("jian");
    },
    getindex(val) {
      this.currentIndex = val;
    },
    onpage() {
      this.$router.push({
        name: "startpingu",
      });
    },
    getnexts() {
      const uid = getuid();
      const id = getreviewid();
      // console.log(uid, id);
      getweidu(uid, id).then((res) => {
        this.nextarr = res.data;
        // console.log(this.nextarr, "123131313");
        const indexid = Number(getnext());
        this.num1 = indexid;
      });
    },
    complete(val) {
      let uid = getuid();
      let reviewid = getreviewid();
      let mainid = gettopicid();
      if (val == "add") {
        rightscore(uid, reviewid, mainid).then((res) => {
          this.allTopic = res.data;
          let lastId = this.allTopic[this.allTopic.length - 1].id;
          console.log(this.scorerecord.fullscore, this.questionid);
          if (this.scorerecord.fullscore === "") {
            Dialog.alert({
              title: "提示",
              message: "请作答当前题目",
              confirmButtonColor: "#fff",
              messageAlign: "left",
              getContainer: ".body",
            }).then(() => {
              // on close
            });
            return false;
          }
          if (
            this.scorerecord.fullscore === 0 ||
            this.questionid == lastId
          ) {
            console.log(uid, reviewid, this.scorerecord.fullscore);
            let data = {
              trecord_id: this.trecord_id,
              toprecord_id: this.toprecord_id,
              uid: uid,
              review_id: reviewid,
              main_id: mainid,
              topic_id: this.questionid,
              score: this.scorerecord.fullscore,
              ans_record: JSON.stringify(this.setarr),
            };
            console.log(data);
            recordadd(data).then((res) => {
              console.log(res, "添加答题记录");
              if (res.code == "1") {
                this.nextMainConfirm(uid, reviewid, mainid);
                Notify({ type: 'success', message: res.msg});
              }
            });
          } else {
            let data = {
              trecord_id: this.trecord_id,
              toprecord_id: this.toprecord_id,
              uid: uid,
              review_id: reviewid,
              main_id: mainid,
              topic_id: this.questionid,
              score: this.scorerecord.fullscore,
              ans_record: JSON.stringify(this.setarr),
            };
            console.log(data);
            recordadd(data).then((res) => {
              console.log(res, "添加答题记录");
              if (res.code == "1") {
                gettablelist(uid, reviewid, mainid).then((res) => {
                  console.log(res, "加表格的数据");
                  // return
                  if (res.data.topic_type === "1") {
                    this.$router.push({
                      name: "addtable",
                    });
                  } else if (res.data.topic_type === "2") {
                    console.log(res.data.topic_type);
                    this.$router.push({
                      name: "choosepicture",
                    });
                  } else if (res.data.topic_type === "3") {
                    this.$router.push({
                      name: "addTruefalse",
                    });
                    window.location.reload();
                  }
                });
                Notify({ type: 'success', message: res.msg});
              }
            });
          }
        });
      } else if (val == "jian") {
        let preTopicSort = this.topic_sort - 1;
        console.log(uid, preTopicSort, reviewid, mainid); //1636 1 32 985
        topicRecord(uid, preTopicSort, reviewid, mainid).then((res) => {
          console.log(res);
          if (res.data.topic_type === "1") {
            this.$router.push({
              name: "addtable",
              query: {
                topic_sort: preTopicSort,
              },
            });
          } else if (res.data.topic_type === "2") {
            console.log(res.data.topic_type);
            this.$router.push({
              name: "choosepicture",
              query: {
                topic_sort: preTopicSort,
              },
            });
          } else if (res.data.topic_type === "3") {
            this.$router.push({
              name: "addTruefalse",
              query: {
                topic_sort: preTopicSort,
              },
            });
            window.location.reload();
          }
        });
      } else if (val == "finish") {
        if (this.scorerecord.fullscore == "") {
          this.scorerecord.fullscore = 0;
        }
        console.log(uid, reviewid, this.scorerecord.fullscore);
        let data = {
          trecord_id: this.trecord_id,
          toprecord_id: this.toprecord_id,
          uid: uid,
          review_id: reviewid,
          main_id: mainid,
          topic_id: this.questionid,
          score: this.scorerecord.fullscore,
          ans_record: JSON.stringify(this.setarr),
        };
        console.log(data);
        recordadd(data).then((res) => {
          console.log(res, "添加答题记录");
          if (res.code == "1") {
            this.nextMainConfirm(uid, reviewid, mainid);
            Notify({ type: 'success', message: res.msg});
          }
        });
      }
    },
    // 添加答题记录
    // nextTopic(uid, reviewid, mainid) {
    //   if (this.scorerecord.fullscore == "") {
    //     this.scorerecord.fullscore = 0;
    //   }
    //   console.log(uid, reviewid, this.scorerecord.fullscore);
    //   let data = {
    //     trecord_id: this.trecord_id,
    //     toprecord_id: this.toprecord_id,
    //     uid: uid,
    //     review_id: reviewid,
    //     main_id: mainid,
    //     topic_id: this.questionid,
    //     score: this.scorerecord.fullscore,
    //     ans_record: JSON.stringify(this.setarr),
    //   };
    //   console.log(data);
    //   recordadd(data).then((res) => {
    //     console.log(res, "添加答题记录");
    //     Notify(res.msg);
    //   });
    // },
    // 下一维度
    nextMainConfirm(uid, reviewid, mainid) {
      let data = {
        uid: uid,
        review_id: reviewid,
        main_id: mainid,
      };
      finishAsses(data).then((res) => {
        Notify({ type: 'success', message: res.msg});
        console.log(this.nextname);
        if (this.nextname == "最后一个维度了") {
          if (this.isend) {
            Toast.success({
              duration: 3000,
              forbidClick: true,
              message: "恭喜你，完成评估",
              onClose: () => {
                this.$router.push({
                  name: "startpingu",
                });
              },
            });
          }else{
            Dialog.alert({
              title: "提示",
              message: "本维度已完成评估，请选择未完成的维度继续作答",
              confirmButtonColor: "#fff",
              messageAlign: "left",
              getContainer: ".body",
            }).then(() => {
              this.$router.push({
                name: "startpingu",
              });
            });
          }
        } else {
          this.$dialog
            .confirm({
              title: "提示",
              message:
                "本维度的测评已结束，下一个测评维度是" +
                '<span style="color:red">' +
                this.nextname +
                "</span>",
              confirmButtonColor: "#fff",
              messageAlign: "left",
              confirmButtonText: "继续评估",
              cancelButtonText: "手动调整",
              getContainer: ".body",
            })
            .then(() => {
              settopicid(this.nextmainid);
              setltemname(this.nextname);
              let nextuid = getuid();
              let nextreviewid = getreviewid();
              console.log(nextuid, nextreviewid, this.nextmainid);
              gettablelist(nextuid, nextreviewid, this.nextmainid).then(
                (res) => {
                  console.log(res);
                  if (res.data.topic_type === "1") {
                    this.$router.push({
                      name: "addtable",
                    });
                  } else if (res.data.topic_type === "2") {
                    this.$router.push({
                      name: "choosepicture",
                    });
                  } else if (res.data.topic_type === "3") {
                    this.$router.push({
                      name: "addTruefalse",
                    });
                    window.location.reload();
                  }
                }
              );
            })
            .catch(() => {
              this.$router.push({
                name: "startpingu",
              });
            });
        }
      });
    },

    scoreshow() {
      const uid = getuid();
      const reviewid = getreviewid();
      const mainid = gettopicid();
      this.show = true;
      rightscore(uid, reviewid, mainid).then((res) => {
        console.log(res);
        this.scorearr = res.data;
      });
    },
    manual(val) {
      this.auto = val;
      if (val === "手动") {
        const coppy = JSON.parse(JSON.stringify(this.scorerecord.fullscore));
        this.scorerecord.fullcoppy = coppy;
        this.scorerecord.fullscore = "";
        this.text = "自动";
        this.zero = false;
        this.zerofive = false;
        this.one = false;
        console.log(this.scorerecord.fullcoppy);
      } else {
        this.zero = false;
        this.zerofive = false;
        this.one = false;
        this.scorerecord.fullscore = this.scorerecord.fullcoppy;
        console.log(this.scorerecord.fullscore);
        this.text = "手动";
      }
    },
    deltablemodel() {
      this.tableshow = false;
    },
    opentable() {
      this.tableshow = !this.tableshow;
    },
    deleteitem() {
      this.$dialog
        .confirm({
          title: "提示",
          message: "删除项目记录后会影响本题得分，确定删除吗？",
          confirmButtonColor: "#fff",
          messageAlign: "left",
          getContainer: ".body",
        })
        .then(() => {
          alert("确定");
          // on confirm
        })
        .catch(() => {
          alert("取消");
          // on cancel
        });
    },
    choose(val, index, itemid) {
      const fullmark = Number(this.addTruefalsedatas.full_mark);
      const halfmark = Number(this.addTruefalsedatas.half_mark);
      let csskey = this.active_tab1.indexOf(itemid);
      let csskey2 = this.active_tab2.indexOf(itemid);
      console.log(this.active_tab1, "hello ");
      if (val == "false") {
        this.isfalse = !this.isfalse;
        this.istrue = false;
        if (csskey2 > -1) {
          // 已选中,点击取消
          this.active_tab2.forEach((item, index) => {
            if (item == itemid) {
              this.active_tab2.splice(index, 1);
            }
          });
          console.log(this.active_tab2, "已经取消");
        } else {
          this.active_tab2.push(itemid);
          this.active_tab1.forEach((item, index) => {
            if (item == itemid) {
              this.active_tab1.splice(index, 1);
            }
          });

          // this.savenum -= 1;
        }
        for (let i = 0; i < this.setarr.length; i++) {
          const topic_id = this.setarr[i].topic_id;
          if (itemid == topic_id) {
            this.setarr[i].answer = 0;
          }
        }
      }
      if (val == "true") {
        if (csskey > -1) {
          // 已选中,点击取消
          this.active_tab1.forEach((item, index) => {
            if (item == itemid) {
              this.active_tab1.splice(index, 1);
            }
          });
          console.log(this.active_tab2, "已经取消");
        } else {
          this.active_tab1.push(itemid);
          this.active_tab2.forEach((item, index) => {
            if (item == itemid) {
              this.active_tab2.splice(index, 1);
            }
          });
        }
        this.istrue = !this.istrue;
        this.isfalse = false;
        this.activeindex = index;

        for (let i = 0; i < this.setarr.length; i++) {
          const topic_id = this.setarr[i].topic_id;
          if (itemid == topic_id) {
            this.setarr[i].answer = 1;
          }
        }
      }
      this.savenum = this.active_tab1.length;
      // console.log(this.savenum);
      if (this.savenum >= fullmark) {
        this.scorerecord.fullscore = 1;
        this.scorerecord.fullcoppy = 1;
      } else if (this.savenum < fullmark && this.savenum >= halfmark) {
        this.scorerecord.fullscore = 0.5;
        this.scorerecord.fullcoppy = 0.5;
        this.isok = true;
      } else {
        this.scorerecord.fullscore = 0;
        this.scorerecord.fullcoppy = 0;
        this.isok = true;
      }
      console.log(this.savenum, "次数");
    },
    // 回看
    backSee(topic_sort, toprecord_id) {
      if (toprecord_id != 0) {
        let uid = getuid();
        let reviewid = getreviewid();
        let mainid = gettopicid();
        topicRecord(uid, topic_sort, reviewid, mainid).then((res) => {
          console.log(res);
          if (res.data.topic_type === "1") {
            this.$router.push({
              name: "addtable",
              query: {
                topic_sort: topic_sort,
              },
            });
            window.location.reload();
          } else if (res.data.topic_type === "2") {
            console.log(res.data.topic_type);
            this.$router.push({
              name: "choosepicture",
              query: {
                topic_sort: topic_sort,
              },
            });
          } else if (res.data.topic_type === "3") {
            this.$router.push({
              name: "addTruefalse",
              query: {
                topic_sort: topic_sort,
              },
            });
          }
        });
      }
    },
    overlayTip() {
      this.$dialog
        .confirm({
          title: "提示",
          message: "当前状态可查看，但无法编辑，点击确认即可返回维度列表",
          confirmButtonColor: "#fff",
          messageAlign: "left",
          getContainer: ".body",
        })
        .then(() => {
          this.$router.push({
            name: "startpingu",
          });
        })
        .catch(() => {
          // console.log("取消");
        });
    },
  },
};
</script>

<style lang="less" scoped>
.body{
  min-height: 100vh;
  background: url(../src/img/bj.png) top left/100% auto no-repeat;
  padding-bottom: .7rem;
  button{
    border: none;
  }
  .van-nav-bar{
    background: none;
    &::after{
      border: none;
    }
    /deep/ .van-nav-bar__left{
      font-size: .14rem;
      .van-nav-bar__text{
        color: #666;
      }
      .van-icon{
        font-size: .14rem;
        color: #666;
        margin: 0;
      }
    }
    .btn {
      width: 1rem;
      height: .34rem;
      background: #f14947;
      border-radius: .06rem;
      font-size: .14rem;
      border: none;
    }
  }
  .from{
    padding: 0 16px;
  }
  .mycard {
    // width: 95%;
    padding: .13rem .16rem;
    margin: 0 auto;
    font-size: .16rem;
    background: #ffffff;
    border-radius: .06rem;
    margin-top: .1rem;
    box-shadow: 0 0 .1rem .01rem rgba(157,4,4,0.05);
    box-sizing: border-box;
    .pingubtn {
      height: .34rem;
      background: #EEA837;
      border-radius: .06rem;
      font-size: .16rem;
      overflow: hidden;
      float: left;
      margin-right: .16rem;
    }
    .text {
      color: #333333;
      line-height: .35rem;
      letter-spacing: .01rem;
    }
  }
  .choose {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .checkedbox {
      height: .32rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: .18rem;
      font-size: 0;
      p {
        width: 1.5rem;
        font-size: .16rem;
        color: #333333;
        margin: 0 .34rem 0 0;
      }
      button {
        width: .48rem;
        height: .32rem;
        border-radius: .06rem;
        border: 1px solid #cccccc;
        color: #999999;
        background: #fff;
        font-size: .2rem;
        box-sizing: border-box;
      }
      button:nth-child(1){
        margin-right: .1rem;
      }
      .trues {
        background: #fff3f3;
        border: 1px solid #ff8a88;
        color: red;
      }
      .falses {
        background: #fff3f3;
        border: 1px solid #ff8a88;
        color: red;
      }
    }
  }
  .scorebtn {
    height: .44rem;
    position: fixed;
    right: 0;
    top: 70%;
    display: flex;
    .box {
      width: .44rem;
      height: .44rem;
      background: #FFECEC;
      box-shadow: 0 0 .06rem .01rem rgba(110,8,8,0.23);
      &::before{
        position:absolute;
        top:.06rem;
        left:-.17rem;
        content:'';
        width:.32rem;
        height:.32rem;
        background:#FFECEC;
        transform: rotate(135deg);
        box-shadow: 0 0 .06rem .01rem rgba(110,8,8,0.23);
      }
      &::after{
        content:'分数';
        position:absolute;
        top:0;
        left: 0;
        width:.44rem;
        height:.44rem;
        background:#FFECEC;
        font-size: .15rem;
        color: #F14947;
        line-height: .44rem;
        text-align: center;
      }
    }
  }
  .scorebox {
    height: 100%;
    background: #ffffff;
    padding: .24rem .24rem .08rem;
    position: fixed;
    border-radius: .2rem .2rem 0 0;
    top: 0;
    right: 0;
    left: auto;
    transform: none;
  }
  .calculatescore {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: .16rem;
    p {
      color: #f14947;
      font-size: .16rem;
      margin: 0;
      width: .34rem;
    }
    ul {
      display: flex;
      li {
        width: .64rem;
        height: .34rem;
        background: #ffffff;
        border-radius: .17rem;
        border: 1px solid #cccccc;
        font-size: .15rem;
        color: #666666;
        text-align: center;
        line-height: .34rem;
        margin-left: .2rem;
      }
      li.active{
        background: #f1b207;
        color: #fff;
        border: 1px solid #f1b207;
      }
    }
  }
  .calculatescore:last-child{
    margin-bottom: 0.4rem;
  }
  .fixedcard {
    width: 100%;
    height: .7rem;
    background: #ffffff;
    box-shadow: 0 0 .06rem .01rem rgba(157, 4, 4, 0.16);
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 55;
    box-sizing: border-box;
    padding: 0 .2rem;
    .score {
      font-size: 0;
      span {
        display: inline-block;
        width: .56rem;
        height: .34rem;
        text-align: center;
        line-height: .34rem;
        background: #ffffff;
        border-radius: .17rem;
        border: 1px solid #cccccc;
        font-size: .15rem;
        color: #666666;
        margin-left: .1rem;
      }
      .active {
        background: #eea837;
        color: #fff;
        border-color: #eea837;
      }
      .active1 {
        background: #eea837;
        color: #fff;
        border-color: #eea837;
      }
    }
    .buttongroup {
      display: flex;
      div {
        width: .96rem;
        height: .34rem;
        border-radius: .17rem;
        text-align: center;
        line-height: .34rem;
        font-size: .16rem;
        margin-left: .11rem;
      }
    }
    .preTopic{
      height: fit-content;
      position: absolute;
      left: .2rem;
      margin: auto;
      div{
        margin-left: 0;      
      }
    }
  }
  /deep/ .van-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
  /deep/ .van-dialog {
    width: 4.06rem;
    padding-bottom: .2rem;
    .van-dialog__header {
      text-align: left;
      padding: .2rem .24rem 0;
      color: #666;
    }
    .van-dialog__header::before {
      content: "!";
      display: inline-block;
      width: .18rem;
      height: .18rem;
      border-radius: 50%;
      text-align: center;
      font-size: .18rem;
      vertical-align: text-bottom;
      margin-right: .06rem;
      line-height: .2rem;
      padding: 0 0 .01rem .01rem;
      background: #ffaa20;
      color: #fff;
    }
    .van-dialog__message--has-title {
      padding-top: .1rem;
      padding-bottom: .27rem;
      font-size: .14rem;
      line-height: .3rem;
    }
    .van-dialog__footer {
      justify-content: center;
      overflow: initial;
      .van-button {
        width: .94rem;
        height: .34rem;
        flex: none;
        border-radius: .06rem;
        margin: 0 .13rem;
        font-size: .16rem;
      }
      .van-dialog__cancel {
        border: 1px solid #b2b2b2;
        color: #707070;
      }
      .van-dialog__confirm {
        background: #fa4b52;
        color: #fff;
      }
    }
    [class*="van-hairline"]::after {
      border: none;
    }
  }
}
@media only screen and (min-width: 1024px) {
  .body{
    .choose{
      width: 95%;
      justify-content: flex-start;
      .checkedbox{
        margin-right: .68rem;
        p{
          margin-right: .16rem;
        }
        button:nth-child(1){
          margin-right: .16rem;
        }
      }
      .checkedbox:nth-child(3n){
        margin-right: 0;
      }
    }
  }
}
</style>