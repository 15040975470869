(function(doc, win) {
	document.documentElement.style.fontSize = (document.documentElement.clientWidth > 768 ? 768 : document.documentElement.clientWidth) / 12 + "px";
	var docEl = win.document.documentElement;
	var resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize';
	var refreshRem = function() {
		var clientWidth = win.innerWidth ||
			doc.documentElement.clientWidth ||
			doc.body.clientWidth;
		var clientHeight = win.innerHeight ||
			doc.documentElement.clientHeight ||
			doc.body.clientHeight;

		if(!clientWidth) return;
		var fz;
		var width = clientWidth;
		var drp = window.devicePixelRatio;
		if(width > 768){
			width = 768;
		}
		fz = 100 * width / 768;
		if (clientWidth >= 1024 && clientWidth >= clientHeight) {
			fz = 100 * width / 768 * (2 / drp);
		}
		docEl.style.fontSize = fz + 'px';
	};

	if(!doc.addEventListener) return;
	win.addEventListener(resizeEvt, refreshRem, false);
	doc.addEventListener('DOMContentLoaded', refreshRem, false);
	refreshRem();

})(document, window);