import Vue from 'vue'
import App from './App.vue'
import router from "./router/index"
import 'amfe-flexible'
import "./plugin/index"
import '/src/styles/iconfont.css'
import  store from './store'
import './plugin/rem'
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
