import Vue from 'vue'
import axios from "axios"
// import router from "../router/index"
import { Notify } from "vant"
import { gettoken, getid, gettype } from "./token"
// const sysConfig = require("./baseurl.js");
Vue.use(Notify);
// console.log(process);
const requests = axios.create({
  baseURL: "https://homeapi.xingxiwang.com", // 线上接口
  // baseURL: "https://hapi.must520.com", // 测试接口
  // baseURL: "/all",
  timeout: 15000,
})
// requests.interceptors.request.use(config => {
//     // config.headers.Authorization=`Bearer ${gettoken()}`
//     console.log(config)
//     return config
// })

// requests.interceptors.response.use(res => {
//     return res.data
// }, error => {
//     if (error.response.status == 401) {
//         Notify({ type: "warning", message: "身份信息已失效" });
//         router.replace("/login")
//     }

//     return Promise.reject(new Error("faile"))
// })

/**
 * 请求拦截
 */
requests.interceptors.request.use(config => {
  config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
  config.headers.token = gettoken() || ''
  config.headers.organid = getid() || ''
  config.headers.type = gettype()
  config.headers.Accept = '*/*'
  // config.params = {
  //   // _t: new Date().getTime()
  // }
  // 默认参数
  // const defaults = {}
  // 防止缓存,GET 请求默认带_t参数
  if (config.method === 'get') {
    config.params = {
      ...config.params,
      ...{ _t: new Date().getTime() }
    }
  }
  // console.log(config);
  return config
}, err => {
  return err
})
/**
 * 响应拦截
 */
requests.interceptors.response.use(response => {
  const res = {
    code: response.data.code * 1,
    msg: response.data.message,
    data: response.data.data['error-info']
  }
  return res
}, err => {
  return err
})

export default requests






































































