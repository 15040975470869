<template>
  <div class="body" @click="hidePanel">
    <van-nav-bar left-text="评估记录" right-text="返回">
      <template #right>
        <p @click="btnshow">{{ username }}<van-icon name="play" /></p>
        <van-button v-if="btnisshow" class="btn" type="warning" @click="exit" id="myPanel">退出登录</van-button>
      </template>
    </van-nav-bar>
    <div class="from">
      <van-form @submit="onSubmit" input-align="center">
        <van-field class="myinput1" v-model="newstime" name="" label="评估日期" @click="showPicker = true" clearable clear-trigger="always" @clear="from.stime = ''" colon @focus="stopKey"/>
        <span class="span">-</span>
        <van-field class="myinput2" v-model="newetime" @click="showPicker2 = true" clearable clear-trigger="always" @clear="from.etime = ''" @focus="stopKey"/>
        <van-field v-model="from.name" label="学生姓名" class="userName" colon/>
        <van-field v-model="from.mobile" label="手机号" class="phone" :maxlength="11" colon/>
        <van-button class="serchbtn" type="warning" @click="serch"> <van-icon name="search"/>搜索 </van-button>
      </van-form>
      <div class="mycard" v-for="(item, index) in studentlist" :key="index">
        <div class="text">
          <span style="margin-right: .2rem;">{{ item.uid }}</span>
          <span>{{ item['stu_name'] }}</span>
        </div>
        <div class="num">
          <p class="numtop"><van-icon name="calendar-o" />评估次数：{{ item['ass_num'] }}次</p>
          <p class="time">
            {{ moment(Number(item['ass_date'] * 1000)).format('YYYY-MM-DD') }}
            {{ item['ass_time'] }}
          </p>
        </div>
        <van-button @click="pingu(item)" class="pingubtn" type="danger">开始评估</van-button>
      </div>
    </div>
    <!-- 评估日期弹窗 -->
    <van-calendar v-model="showPicker" :min-date="new Date('1970/01/01')" @confirm="onConfirm" @cancel="showPicker = false" />
    <van-calendar v-model="showPicker2" :min-date="new Date('1970/01/01')" @confirm="onConfirm2" @cancel="showPicker2 = false" />
  </div>
</template>

<script>
import moment from 'moment'
import { Notify} from "vant"
import { getname, removetoken, removeed, removetype, removename } from '/src/api/token.js'
import { setuid, setreviewid } from '/src/api/userdata.js'
import { getteacherlist, getstudent } from '../../api/index'
export default {
  data() {
    return {
      moment,
      username: '',
      btnisshow: false,
      from: {
        name: '',
        mobile: '',
        stime: '',
        etime: ''
      },
      showPicker: false,
      showPicker2: false,
      studentlist: [],
      newstime: '',
      newetime: ''
    }
  },
  mounted() {
    this.getlist()
    this.getname()
    console.log(this.studentlist)
  },
  methods: {
    serch() {
      getstudent(this.from).then((res) => {
        console.log(res)
        if (res.code === 1 && res.data.length > 0) {
          this.studentlist = res.data
        } else {
          this.studentlist = []
        }
      })
    },
    getname() {
      const teachername = getname()
      this.username = teachername
    },
    getlist() {
      getteacherlist().then((res) => {
        if (res.code === 1) {
          this.studentlist = res.data
          console.log(this.studentlist)
        }else if(res.code === 9990){
          Notify({ type: 'danger', message: res.msg});
          this.$router.push({
            name: 'login'
          })
        }
      })
    },
    btnshow(e) {
      e.stopPropagation();
      this.btnisshow = !this.btnisshow
    },
    hidePanel (event){
     let myPanel = document.getElementById("myPanel");
     if(myPanel){
        if(!myPanel.contains(event.target)){
           this.btnisshow = false;
        }
      }
    },
    exit() {
      removetype()
      removeed()
      removetoken()
      removename()
      this.$router.push({
        name: 'login'
      })
    },
    onConfirm(time) {
      const newtime = moment(time).format('YYYY-MM-DD 00:00:00')
      let ntime = new Date(Date.parse(newtime.replace(/-/g, ' '))).getTime() / 1000
      this.newstime = newtime.replace(' 00:00:00', '')
      this.from.stime = ntime
      this.showPicker = false
    },
    onConfirm2(time) {
      const newtime = moment(time).format('YYYY-MM-DD 23:59:59')
      let ntime = new Date(Date.parse(newtime.replace(/-/g, ' '))).getTime() / 1000
      this.newetime = newtime.replace(' 23:59:59', '')
      this.from.etime = ntime
      this.showPicker2 = false

    },
    pingu(val) {
      setuid(val.uid)
      setreviewid(val.id)
      this.$router.push({
        name: 'startpingu'
      })
    },
    onSubmit() {},
    stopKey() {
      document.activeElement.blur();
    },
  }
}
</script>

<style lang="less" scoped>
  .body{
    background: url(src/img/bj.png) top left/100% auto no-repeat;
    button{
      border: none;
    }
    .van-nav-bar{
      background: none;
      /deep/ .van-nav-bar__left, .van-nav-bar__right{
        font-size: .16rem;
        color: #333333;
        .van-nav-bar__text{
          color: #333;
        }
      }
      /deep/ .van-nav-bar__left{
        font-size: .18rem;
        font-weight: bold;
      }
      .van-icon{
        font-size: .1rem;
        color: #FFAA20;
        transform: rotate(90deg);
      }
      .btn {
        width: 1.03rem;
        height: .4rem;
        position: absolute;
        right: .1rem;
        top: .4rem;
        background: #fff;
        box-shadow: 0 0 .1rem .01rem rgba(151,0,0,0.11);
        color: #333;
        border-radius: .06rem;
        font-size: .14rem;
        padding: .12rem;
        .van-button__content::before{
          content: "";
          width: .14rem;
          height: .14rem;
          background: url("./src/img/ICON2x.png") top left/100% no-repeat;
          margin-right: .04rem;
        }
      }
      &::after{
        border: none;
      }
    }
    .from{
      background: linear-gradient(180deg, rgba(255, 255, 255, .7) 0%, rgba(220, 220, 220, .7) 100%);
      border-radius: .3rem .3rem 0 0;
      min-height: 100vh;
      padding: 0 16px;
      .van-form{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        // padding: 0 .2rem;
        .van-cell{
          width: fit-content;
          line-height: .34rem;
          padding: 0.14rem 0.2rem 0.14rem 0;
          background: none;
          &::after{
            display: none;
          }
        }
        .myinput2{
          padding-left: 0.1rem;
        }
        /deep/ .van-field__label{
          width: auto;
          color: #333;
          padding-right: .05rem;
          margin-right: 0;
          font-size: .14rem;
        }
        /deep/ .van-field__value{
          width: 1.8rem;
          height: .32rem;
          background: #FFFFFF;
          border-radius: .06rem;
          border: 1px solid #EFEFEF;
          font-size: .14rem;
        }
        .userName{
          padding: 0 .2rem 0 0;
          /deep/  .van-field__value{
            width: 1.3rem;
          }
        }
        .phone{
          padding: 0 .2rem 0 0;
        }
        .span{
          font-size: .14rem;
          line-height: .32rem;
        }
        .serchbtn{
          width: .92rem;
          height: .34rem;
          background: #F14947;
          border-radius: .06rem;
          font-size: .14rem;
          padding: 0 .15rem;
          letter-spacing: .01rem;
          .van-icon{
            font-size: .16rem;
            vertical-align: bottom;
          }
        }
      }
      .mycard{
        // width: 95%;
        height: .72rem;
        background: #FFFFFF;
        border-radius: .06rem;
        margin: 0.08rem auto;
        padding: .14rem .27rem .14rem .16rem;
        font-size: .14rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        color: #333;
        .num{
          .van-icon{
            font-size: .18rem;
            color: #FFAA20;
            vertical-align: text-bottom;
          }
          .time{
            font-size: .12rem;
            color: #999;
            margin-left: .2rem;
          }
        }
        .pingubtn{
          width: .9rem;
          height: .34rem;
          background: #F14947;
          border-radius: .06rem;
          padding: 0 .15rem;
          font-size: .14rem;
        }
      }
    }
  }
@media screen and (max-width: 479px) {
  .body .from .van-form /deep/ .van-field__value{
    width: 1.4rem;
  }
  .body .from .van-form /deep/ .userName .van-field__value{
    width: .8rem;
  }
}
@media screen and (min-width: 479px) {
  .body .from .van-form /deep/ .van-field__value{
    width: 1.4rem;
  }
  .body .from .van-form /deep/ .userName .van-field__value{
    width: .8rem;
  }
}
@media screen and (min-width: 639px) {
  .body .from .van-form /deep/ .userName .van-field__value{
    width: 1rem;
  }
}
@media screen and (min-width: 640px) and (max-width: 768px) {
  .body .from .van-form /deep/ .userName .van-field__value{
    width: 1.8rem;
  }
}
@media screen and (min-width: 769px) and (max-width: 820px) {
  .body .from .van-form /deep/ .van-field__value{
    width: 1rem;
  }
  .body .from .van-form /deep/ .userName .van-field__value{
    width: .7rem;
  }
}
@media screen and (min-width: 821px) {
  .body .from .van-form /deep/ .van-field__value{
    width: 1.2rem;
  }
  .body .from .van-form /deep/ .userName .van-field__value{
    width: .8rem;
  }
}
@media screen and (min-width: 1024px) {
  .body .from .van-form /deep/ .van-field__value{
    width: 1.6rem;
  }
  .body .from .van-form /deep/ .userName .van-field__value{
    width: .8rem;
  }
}
</style>
