import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter)
import login from "../view/login/index.vue"
import AssessmentRecords from "../view/AssessmentRecords/index.vue"
import startpingu from "../view/AssessmentRecords/components/startpingu.vue"
import addtable from "../view/AssessmentRecords/components/addtable.vue"
import addTruefalse from "../view/AssessmentRecords/components/addTruefalse.vue"
import addexercises from "../view/AssessmentRecords/components/addexercises.vue"
import choosepicture from "../view/AssessmentRecords/components/choosepicture.vue"
const router = new VueRouter({
    routes: [
        {
            path: "/",
            redirect: "/login/"
        },
        {
            path: "/login",
            component: login,
            name: 'login'
        },
        {
            path: "/AssessmentRecords",
            component: AssessmentRecords,
            name: 'AssessmentRecords',
        },

        {
            path: "/startpingu",
            component: startpingu,
            name: 'startpingu'
        },
        {
            path: "/addtable",
            component: addtable,
            name: 'addtable'
        },
        {
            path: "/addTruefalse",
            component: addTruefalse,
            name: 'addTruefalse'
        },
        {
            path: "/addexercises",
            component: addexercises,
            name: 'addexercises'
        },
        {
            path: "/choosepicture",
            component: choosepicture,
            name: 'choosepicture'
        }
    ]
})

export default router

