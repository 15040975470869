<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  created() {},
  methods: {},
};
</script>

<style lang="less" scoped>
#app {
  letter-spacing: 2px;
}
html,
body {
  width: 100%;
  height: 100%;
}
.body {
  width: 100%;
  height: 100%;
  position: relative;
}
</style>


